<script setup>
import { onMounted } from 'vue';
import { getOrCreateDataObject } from 'o365-dataobject';

const props = defineProps({
    sjaID: Number,
    readOnly: Boolean
});

const dsIncidents = getOrCreateDataObject({
    id: `dsIncidents_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJAIncidents',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJAIncidents',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJA_ID', },
        { name: 'Incident_ID', },
        { name: 'Incident', },
        { name: 'IncidentDate', },
        { name: 'Severity', },
        { name: 'Consequence', },
        { name: 'SeverityColor', },
        { name: 'ConsequenceColor', },
        { name: 'OpenWorkflows', },
        { name: 'Evaluated', }

    ],
    whereClause: "SJA_ID = " + props.sjaID
});

const dsIncidentsLkp = getOrCreateDataObject({
    id: `dsIncidentsLkp_${props.sjaID}`,
    maxRecords: 25,
    viewName: 'aviw_Incident_Incidents',
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'OrgUnit', },
        { name: 'Description', },
        { name: 'Title', },
        { name: 'OrgUnit_ID', },
        { name: 'Submitted', },
        { name: 'Type', }
    ],
});

function addIncident(pRow) {
    dsIncidents.createNew({
        SJA_ID: props.sjaID,
        Incident_ID: pRow.ID
    });
}

onMounted(() => {
    dsIncidents.load();
    dsIncidentsLkp.load();
})

</script>

<template>
    <ODataLookup v-if="!props.readOnly" :data-object="dsIncidentsLkp" :bind="sel => { addIncident(sel) }">
        <OColumn :headerName="$t('ID')" field="ID" width="80"></OColumn>
        <OColumn :headerName="$t('Title')" field="Title" width="150"></OColumn>
        <OColumn :headerName="$t('Description')" field="Description" width="250"></OColumn>
        <template #target="scope" @click="scope.open">
            <button :ref="scope.target" class="p-0 btn btn-link">
                <div class="d-flex" :title="$t('Link a incident to the SJA')">
                    <h5>{{ $t('Incidents') }}</h5>
                    <i class="mx-1 bi bi-plus-circle-fill"></i>
                </div>
            </button>
        </template>
    </ODataLookup>
    <div v-if="props.readOnly" class="d-flex"> 
        <h5>{{ $t('Incidents') }}</h5>
    </div>
    <hr class="m-0" />
    <div class="d-flex flex-wrap mt-2">
        <div v-for="(incident, index) in dsIncidents.data" :key="index">
            <div class="d-flex justify-content-between mt-2 me-4" v-if="!incident.isNewRecord" style="width:420px">
                <div class="d-flex flex-column">
                    <a class="d-inline-block text-truncate" style="max-width: 400px" :href="'/incident?ID=' + incident.Incident_ID" target="_blank">
                        <span>{{ incident.Incident_ID }} - {{ incident.Incident }}</span>
                    </a>
                    <div class="text-muted small" v-if="incident.Evaluated">
                        <span class="fw-bold me-1">{{ $t('Evaluated') }}:</span> <span>{{ $formatDate(incident.Evaluated,
                            'General Date Short Time') }}</span>
                    </div>
                    <div class="text-muted small d-flex align-items-center">
                        <span class="fw-bold me-1">{{ $t('Consequence') }}:</span> <span 
                            class="rounded border align-middle me-1 assessment_circle"
                            :style="{ background: incident.ConsequenceColor }"></span> <span>{{ incident.Consequence
                            }}</span>
                        <span class="fw-bold ms-3 me-1">{{ $t('Severity') }}:</span> <span
                            class="rounded border align-middle me-1 assessment_circle"
                            :style="{ background: incident.SeverityColor }"></span> <span>{{ incident.Severity }}</span>
                    </div>
                    <div class="text-muted small" v-if="incident.OpenMM >= 0 && incident.OpenMM !== null">
                        <span class="fw-bold me-1">{{ $t('Open Measures') }}:</span> <span>{{ incident.OpenMM }}</span>
                    </div>
                </div>
                <OActionDelete confirm v-if="!props.readOnly" :dataObject="dsIncidents" :row="incident" class="btn btn-sm btn-link p-0" :title="$t('Delete incident')" style="-bs-btn-line-height: 1;">
                                <i class="bi bi-x-circle ms-2"></i>
                </OActionDelete>
            </div>
        </div>
    </div>
</template>